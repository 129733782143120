import React from 'react';

import styles from './NotFound.module.css';

function NotFound(props) {
    return (
        <div className={styles.root}>
            <h2 className={styles.title}>Nope.</h2>
            <p className={styles.body}>Your friends gave you a fake link to get you to buzz off. Or maybe you're just bad at typing. Either way: yikes.</p>
        </div>
    );
}

export default NotFound;
