import React from 'react';

import Scoreboard from '../scoreboard/Scoreboard';
import Hand from './Hand';
import Playing from './Playing';
import useGame from '../util/useGame';

import styles from './Game.module.css';

function Game(props) {
    const [game, onPlay, onJudge] = useGame(props.name, props.game);

    return (
        <div>
            <Scoreboard players={game.players} />
            <Playing black={game.black} white={game.played} czar={game.czar} onSelect={onJudge} />
            <Hand cards={game.hand} num={game.black.num} czar={game.czar} onSelect={onPlay} />
            <footer className={styles.footer}><a href="https://cardsagainsthumanity.com">Cards Against Humanity</a> content used under the <a href="https://creativecommons.org/licenses/by-nc-sa/2.0/">Creative Commons BY-NC-SA 2.0 license</a></footer>
        </div>
    );
}

export default Game;
