import React from 'react';

import Player from './Player';

import styles from './Scoreboard.module.css';

function Scoreboard(props) {
    return (
        <div className={styles.root}>
        {
            props.players.map((player,i) =>
                <Player
                    key={i}
                    name={player.name}
                    score={player.score}
                    czar={player.czar}
                    selected={player.selected}
                    disabled={player.disabled}
                />
            )
        }
        </div>
    );
}

export default Scoreboard;
