import { useState, useEffect, useRef } from 'react';

const initialState = {
    state: 'invalid',
    players: [],
    czar: false,
    black: {
        text: '',
        num: 1
    },
    played: [],
    hand: []
};

function useGame(name, game) {
    const [state, setState] = useState(initialState);
    const wsRef = useRef(null);
    useEffect(() => {
        if (!name || !game) return;
        
        wsRef.current = new WebSocket(`${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.host}/api/game/${game}?name=${name}`);
        wsRef.current.addEventListener('open', () => {
            const message = JSON.stringify(['join', { name, game }]);
            wsRef.current.send(message);
        });
        wsRef.current.addEventListener('message', event => {
            const [type, nextState] = JSON.parse(event.data);
            if (type === 'state') {
                if (process.env.NODE_ENV !== 'production') {
                    console.log(nextState);
                }
                setState(nextState);
            }
        });
        wsRef.current.addEventListener('close', event => {
            console.log('Disconnected from server');
            alert('Close');
        });
        wsRef.current.addEventListener('error', event => {
            alert(event);
        });
        return () => {
            console.log('Closing connection');
            wsRef.current.close();
        };
    }, [name, game]);

    const onPlay = (cards) => {
        if (state.state !== 'play') {
            return;
        }

        if (state.czar) {
            return;
        }

        const message = JSON.stringify(['select', { cards }]);
        wsRef.current.send(message);
    };

    const onJudge = (card) => {
        if (state.state !== 'judge') {
            return;
        }

        if (!state.czar) {
            return;
        }

        const message = JSON.stringify(['judge', { card }]);
        wsRef.current.send(message);
    }

    return [state, onPlay, onJudge];
}

export { useGame as default };
