import React, { useState } from 'react';
import cx from 'classnames';

import styles from './JoinForm.module.css';

function JoinForm(props) {
    const [name, setName] = useState('');

    const onClick = () => props.onSubmit(name);

    return (
        <div className={styles.root}>
            <div className={styles.row}>
                <input
                    className={styles.input}
                    type="text"
                    placeholder="Your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <button className={cx(styles.button, styles.primary)} onClick={onClick}>Join</button>
        </div>
    );
}

export default JoinForm;
