import React, { useState } from 'react';

import WhiteCard from '../cards/WhiteCard';

import styles from './Hand.module.css';

function Hand(props) {
    const [selected, setSelected] = useState([]);
    const onClick = (i) => {
        if (!selected.includes(i)) {
            const next = [...selected, i];
            if (next.length === props.num) {
                props.onSelect(next);
                setSelected([])
            } else {
                setSelected(next);
            }
        } else {
            const next = [...selected];
            const index = next.indexOf(i);
            next.splice(index, 1);
            setSelected(next);
        }
    }

    return (
        <div className={styles.root}>
        {
            props.cards.map((card,i) =>
                <WhiteCard
                    key={i}
                    text={card.text}
                    selected={selected.includes(i)}
                    disabled={props.czar}
                    onClick={() => onClick(i)}
                />
            )
        }
        </div>
    );
}

export default Hand;
