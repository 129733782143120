import React from 'react';
import cx from 'classnames';

import styles from './WhiteCard.module.css';

function WhiteCard(props) {
    const classes = {
        [styles.selected]: props.selected,
        [styles.disabled]: props.disabled
    };

    return (
        <div className={cx(styles.root, classes)} onClick={props.onClick}>
            <p className={styles.text}>{props.text}</p>
        </div>
    );
}

export default WhiteCard;
