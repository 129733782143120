import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Setup from './setup/Setup';
import Join from './join/Join';
import NotFound from './notfound/NotFound';

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <Setup/>
                </Route>
                <Route path="/:id" exact>
                    <Join/>
                </Route>
                <Route>
                    <NotFound/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
