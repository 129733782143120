import React from 'react';
import cx from 'classnames';

import styles from './Player.module.css';

function Player(props) {
    const appliedStyles = {
        [styles.selected]: props.selected,
        [styles.czar]: props.czar,
        [styles.disabled]: props.disabled
    }

    return (
        <div className={cx(styles.root, appliedStyles)}>
            <p className={styles.name}>{props.name}</p>
            <p className={styles.score}>{props.score} Point{props.score !== 1 && 's'}</p>
        </div>
    );
}

export default Player;
