import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import Loader from './Loader';

import styles from './Setup.module.css';

function Setup(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const create = () => {
        setLoading(true);
        fetch(`/api/create`, { method: 'POST' })
        .then(response => response.text())
        .then(id => history.push(`/${id}`))
        .catch(() => setLoading(false));
    }

    return (
        <div className={styles.root}>
            <h1 className={styles.title}>Cards Against</h1>
            <h2 className={styles.subtitle}>(...no one in particular)</h2>
            <p>A Cards Against Humanity clone.</p>
            <div className={cx(styles.row, styles.centered)}>
                <button className={cx(styles.button, styles.primary)} onClick={create} disabled={loading}>{ loading ? <Loader/> : 'Start New Game'}</button>
            </div>
            <div className={styles.row}>
                <p>Trying to join a game already in progress? Your friends should have a link for you.</p>
            </div>
            <footer className={styles.footer}>
                A <a href="https://cardsagainsthumanity.com">Cards Against Humanity</a> clone. Cards Against Humanity content is gratefully used under the terms of the <a href="http://creativecommons.org/licenses/by-nc-sa/2.0/">Creative Commons BY-NC-SA 2.0 license</a> and, in turn, this work is published under the same license.
                <div style={{ margin: '8px auto'}}>
                    <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/2.0/">
                        <img alt="Creative Commons License" src="https://mirrors.creativecommons.org/presskit/buttons/88x31/svg/by-nc-sa.svg"/>
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default Setup;
