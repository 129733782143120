import React from 'react';

import WhiteCard from './WhiteCard';

import styles from './CardGroup.module.css';

function CardGroup(props) {
    if (props.cards.length === 1) {
        return (
            <div className={styles.singular}>
                <WhiteCard
                    text={props.cards[0].text}
                    selected={props.selected}
                    onClick={props.onClick}
                />
            </div>
        );
    }

    return (
        <div className={styles.group}>
        {
            props.cards.map((card, i) =>
                <WhiteCard
                    key={i}
                    text={card.text}
                    selected={props.selected}
                    onClick={props.onClick}
                />
            )
        }
        </div>
    );
}

export default CardGroup;
