import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Game from '../game/Game';
import JoinForm from './JoinForm';
import NotFound from '../notfound/NotFound';

function Join(props) {
    const { id: game } = useParams();
    const { state: { name: defaultName } = '' } = useLocation();
    const [valid, setValid] = useState(true);
    const [joined, setJoined] = useState(defaultName);
    const [name, setName] = useState(defaultName);
    useEffect(() => {
        fetch(`/api/game/${game}`).then(response => setValid(response.ok));
    }, [game]);

    const join = async (name) => {
        setName(name);
        setJoined(true);
    };

    if (!valid) {
        return (<NotFound/>);
    }

    if (joined) {
        return (<Game name={name} game={game}/>);
    }

    return (<JoinForm onSubmit={join}/>);
}

export default Join;
