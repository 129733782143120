import React from 'react';

import BlackCard from '../cards/BlackCard';
import CardGroup from '../cards/CardGroup';

import styles from './Playing.module.css';

function Playing(props) {
    const onClick = (i) => {
        props.onSelect(i);
    };

    return (
        <div className={styles.root}>
            <div className={styles.black}>
                <BlackCard text={props.black && props.black.text} />
            </div>
            <div className={styles.white}>
            {
                props.white.map((played, i) =>
                    <CardGroup
                        key={i}
                        cards={played.cards}
                        selected={played.selected}
                        onClick={() => onClick(i)}
                    />
                )
            }
            </div>
        </div>
    );
}

export default Playing;
