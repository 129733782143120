import React from 'react';

import styles from './BlackCard.module.css';

function BlackCard(props) {
    return (
        <div className={styles.root}>
            <p className={styles.text}>{props.text}</p>
        </div>
    );
}

export default BlackCard;
